import React from 'react';
import { Dialog, DialogTitle, Box, Typography, Button } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FieldLabelFormikComponent from '@giro-pdv-terminal-whitelabel/components/FieldLabelFormik.component';

import useDialogHook from '@giro-pdv-terminal-whitelabel/hooks/useDialogRedux.hook';

import dialogConfirmExit from '@giro-pdv-terminal-whitelabel/store/dialogConfirmExit';

import auth from '@giro/shared-store/auth';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const DialogConfirmExitComponent = () => {
  const dispatch = useDispatch();

  const { user } = useAuthHook();

  const { visible, handleClose, handleReset } =
    useDialogHook(dialogConfirmExit);

  const handleExit = () => {
    dispatch(auth.action.unsetToken());
    handleReset();

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <Dialog open={visible} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Typography variant="trasso_body_medium" color="trasso.purple.80">
            Confirmação de saída
          </Typography>
        </Box>
      </DialogTitle>

      <Formik
        initialValues={{
          code: '',
        }}
        onSubmit={() => {}}
        validationSchema={Yup.object({
          code: Yup.string().test(
            'is-valid-code',
            'Código inválido',
            (value) => value === user?.linkedkey
          ),
        })}
        validateOnMount
      >
        {({ errors }) => (
          <Box
            minWidth={400}
            display="flex"
            flexDirection="column"
            gap={2}
            px={3}
            pb={3}
            position="relative"
          >
            <Typography variant="trasso_body_medium" color="gray.400">
              Tem certeza de que deseja sair? Sair agora encerrará a sessão
              atual. Para confirmar sua saída, digite o código de sincronização
              abaixo.
            </Typography>

            <FieldLabelFormikComponent
              name="code"
              label="Código"
              size="small"
            />

            <Button
              variant="outlined"
              onClick={handleExit}
              disabled={Object.keys(errors).length > 0}
            >
              Sair
            </Button>
          </Box>
        )}
      </Formik>
    </Dialog>
  );
};

export default DialogConfirmExitComponent;
