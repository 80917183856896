import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TablePaymentComponent from '../components/TablePayment.component';

import terminalStore from '../store';

import useIsTouchDeviceHook from '@giro-pdv-terminal-whitelabel/hooks/useIsTouchDevice.hook';

const SelectInstammentComponent = () => {
  const dispatch = useDispatch();

  const useIsTouchDevice = useIsTouchDeviceHook();

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  const methodPayment = terminalStoreSelectorState.methodPayment;
  const methodPaymentIsCard = methodPayment === 'card';

  React.useEffect(() => {
    if (useIsTouchDevice) (document as any).body.style.zoom = '82%';

    return () => {
      if (useIsTouchDevice) (document as any).body.style.zoom = '100%';
    };
  }, [useIsTouchDevice]);

  React.useEffect(() => {
    const currentStep = terminalStoreSelectorState.step;
    const lastStep = terminalStoreSelectorState.lastStep;

    const currentStepHigherThanLastStep = currentStep > lastStep;

    if (!methodPaymentIsCard && currentStepHigherThanLastStep) {
      dispatch(terminalStore.action.updateStepNext());
    }

    if (!methodPaymentIsCard && !currentStepHigherThanLastStep) {
      dispatch(terminalStore.action.updateStepPrev());
    }
  }, []);

  return methodPaymentIsCard ? <TablePaymentComponent /> : <></>;
};

export default SelectInstammentComponent;
