import { all } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';

import router from '@giro/shared-store/router';

import login from '@giro-pdv-terminal-whitelabel/modules/login/store';
import terminal from '@giro-pdv-terminal-whitelabel/modules/terminal/store';

import dialogReceipt from './dialogReceipt';
import dialogConfirmExit from './dialogConfirmExit';
import menu from './menu';
import transactions from './transactions';
import pinpad from './pinpad';

function* rootSaga() {
  yield all([
    router.saga.watch(),
    terminal.saga.watch(),
    login.saga.watch(),
    auth.saga.watch(),
    dialogReceipt.saga.watch(),
    dialogConfirmExit.saga.watch(),
    menu.saga.watch(),
    transactions.saga.watch(),
    pinpad.saga.watch(),
  ]);
}

export default rootSaga;
