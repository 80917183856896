import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import terminalStore from '../store';

import websocketUtil from '@giro-pdv-terminal-whitelabel/utils/websocket.util';

import useIsTouchDeviceHook from '@giro-pdv-terminal-whitelabel/hooks/useIsTouchDevice.hook';

const BoxPayment = ({ label, onClick, img }) => {
  return (
    <Box
      bgcolor="primary.light"
      height={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      flexDirection="column"
      gap={1}
      borderRadius={1}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box component={'img'} src={img} alt="credit-card" />
      <Typography
        align="center"
        variant="trasso_heading_medium"
        color="grey.800"
        height={80}
        fontWeight={600}
      >
        {label}
      </Typography>
    </Box>
  );
};

const SelectPaymentComponent = () => {
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const isTouchDevice = useIsTouchDeviceHook();

  const terminalStoreSelectorState = useSelector(
    terminalStore.selector.selectState
  );

  React.useEffect(() => {
    if (isTouchDevice) (document as any).body.style.zoom = '76%';

    return () => {
      if (isTouchDevice) (document as any).body.style.zoom = '100%';
    };
  }, [isTouchDevice]);

  const handleSetPayment = (payment) => () => {
    const linkedKey = user?.linkedkey;

    dispatch(terminalStore.action.updatePayment(payment));
    dispatch(terminalStore.action.updateStepNext());

    websocketUtil.addEvent(linkedKey, {
      event: 'pdv-terminal::method_payment',
      payload: {
        method_payment: payment,
      },
    });
  };

  return (
    <Box>
      <Box
        gap={4}
        display="grid"
        gridTemplateColumns="1fr 1fr"
        alignContent="center"
      >
        <Box gridColumn="2 span">
          <Typography align="center" variant="trasso_heading_medium">
            Valor a pagar:{' '}
            <strong>
              {Number(terminalStoreSelectorState.amount).toLocaleString(
                'pt-br',
                {
                  currency: 'BRL',
                  style: 'currency',
                }
              )}
            </strong>
          </Typography>
        </Box>

        <Box gridColumn="2 span">
          <Divider />
        </Box>

        <Box gridColumn="2 span" mb={-3}>
          <Typography
            align="center"
            variant="overline"
            fontWeight={800}
            color="gray.300"
          >
            Selecione o meio de pagamento
          </Typography>
        </Box>

        <BoxPayment
          img={'/cartao_giro.svg'}
          label={
            <span>
              Cartão de crédito <br />
              ou débito
            </span>
          }
          onClick={handleSetPayment('card')}
        />
        <BoxPayment
          img={'/pix_giro.svg'}
          label="PIX"
          onClick={handleSetPayment('pix')}
        />

        <Box display="flex" flexDirection="column" gridColumn="2 span">
          <Typography variant="overline" fontWeight={600} color="gray.500">
            Base legal: Provimento 127/2022 - Corregedoria Nacional de Justiça
          </Typography>
          <Typography align="justify" variant="caption" color="gray.400">
            I – O Pix, quando cobrado ao destinatário da transferência, terá o
            seu custo suportado pelo gestor da plataforma do Sistema Integrado
            de Pagamentos Eletrônicos – SIPE, sem nenhum repasse correspondente
            aos usuários;
          </Typography>
          <Typography align="justify" variant="caption" color="gray.400">
            II – Os custos da intermediação financeira e/ou de eventual
            parcelamento por cartão de crédito cobrados pela operadora ou
            administradora autorizada a funcionar pelo Banco Central do Brasil
            serão repassados ao usuário e por ele suportados, mediante a
            inclusão dos valores respectivos no pagamento devido;
          </Typography>
          <Typography align="justify" variant="caption" color="gray.400">
            III – O custo do boleto, quando esta for a opção do usuário, pessoa
            jurídica ou física, será incluído no valor devido pela prática do
            ato, devendo essa tarifa ser especificadamente demonstrada de modo
            claro e transparente pelo gestor, na plataforma e no corpo do
            respectivo boleto.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectPaymentComponent;
