import { flow } from 'lodash';
import { takeLatest, put, select, delay, call, take } from 'redux-saga/effects';

import postApiTransactions from '@giro/shared-services/pdv/postApiTransactions.service';

import toaster from '@giro/shared-store/toaster';

import currentStore from '.';

import pinpad from '../pinpad';
import clients from '../clients';

function* handleServicePatch(action) {
  const clientsData = yield select(clients.selector.selectState);

  const currentData = yield select(currentStore.selector.selectCurrent);
  const data = yield select(currentStore.selector.selectData);
  const session = yield select(pinpad.selector.selectSession);
  const dataPinpad = yield select(pinpad.selector.selectData);

  const fields = session?.ret;

  const showToaster = flow(toaster.action.show, put);

  const lastData = dataPinpad?.slice(-1)?.pop();

  const newCurrentData = {
    ...currentData,
    DsMensagemTransacao: String(lastData?.clisitefStatus),
    CdTransacao: data?.meta,
    CdStatusTransacao: action.payload,
    DsBinCartao: fields?.find((f) => f.TipoCampo === 2021)?.Valor,
    DsCardHolder: fields?.find((f) => f.TipoCampo === 1003)?.Valor,
    IdTransacao: fields?.find((f) => f.TipoCampo === 134)?.Valor,
  };

  if (newCurrentData.DsMensagemTransacao === 'undefined') {
    delete newCurrentData.DsMensagemTransacao;
  }

  yield put(currentStore.action.updateCurrent(newCurrentData));

  try {
    yield put(currentStore.action.fetchStart());

    const [success, result] = yield call(postApiTransactions, {
      ...newCurrentData,
      token: clientsData?.payload?.token,
    });

    if (!success) {
      throw result;
    }

    yield put(currentStore.action.fetchSuccess(result));
  } catch (e) {
    yield put(currentStore.action.fetchError());

    yield showToaster({
      message: 'Aconteceu um erro, tente novamente mais tarde',
      variant: 'error',
    });
  }
}

function* watch() {
  yield takeLatest(
    currentStore.constant.ACTION_TYPES.SERVICE.PATCH,
    handleServicePatch
  );
}

export default {
  watch,
};
