import React from 'react';
import { flow } from 'lodash';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector, useDispatch } from 'react-redux';

import useIsMobileHook from '@giro-pdv-terminal-whitelabel/hooks/useIsMobile.hook';
import useDialogHook from '@giro-pdv-terminal-whitelabel/hooks/useDialogRedux.hook';

import HeaderComponent from '@giro-pdv-terminal-whitelabel/components/Header.component';

import menu from '@giro-pdv-terminal-whitelabel/store/menu';
import useIsTotemHook from '@giro-pdv-terminal-whitelabel/hooks/useTotem.hook';

import auth from '@giro/shared-store/auth';
import dialogConfirmExit from '@giro-pdv-terminal-whitelabel/store/dialogConfirmExit';

const AppLayout = ({ children }) => {
  const isMobile = useIsMobileHook();
  const isTotem = useIsTotemHook();

  const dispatch = useDispatch();

  const { handleOpen } = useDialogHook(dialogConfirmExit);

  const dispatchRedux = {
    open: flow(menu.action.open, dispatch),
    close: flow(menu.action.close, dispatch),
    unsetToken: flow(auth.action.unsetToken, dispatch),
  };

  const selectorRedux = {
    menuVisible: useSelector(menu.selector.selectVisible),
  };

  const handleToggleSide = () => {
    if (selectorRedux.menuVisible) {
      dispatchRedux.close();
    }
    if (!selectorRedux.menuVisible) {
      dispatchRedux.open({});
    }
  };

  const menuDown = [
    {
      label: 'Sair',
      onClick: handleOpen,
      icon: (
        <svg
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <use href="/feather-sprite.svg#log-out" />
        </svg>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column">
      <Box id="header-app">
        <HeaderComponent
          sideOpen={selectorRedux.menuVisible}
          handleToggleSide={handleToggleSide}
        />
      </Box>

      <Box
        py={4}
        flex={1}
        minHeight="calc(100vh - 196px)"
        id="containerScroll"
        sx={{
          overflowY: 'auto',
          '@media print': {
            height: '100% !important',
          },
        }}
      >
        <Container maxWidth="lg">{children}</Container>
      </Box>

      <Box bgcolor="grey.100" id="footer-app">
        <Container maxWidth="lg">
          <Box
            height={100}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap={2} alignItems="center">
              <Typography variant="trasso_heading_small" color="grey.500">
                Plataforma gerida e administrada pelo{' '}
                <Box component="span" color="secondary.dark">
                  Operador Nacional do Registro Civil do Brasil
                </Box>
              </Typography>
            </Box>
            <Box color="grey.500">
              {menuDown.map((item, index) => (
                <Button
                  key={index}
                  variant="text"
                  size="small"
                  color="inherit"
                  onClick={item.onClick}
                  startIcon={item.icon}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AppLayout;
