import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ROUTES from '@giro-pdv-terminal-whitelabel/constants/routes.constant';

import TerminalScreen from './screens/Terminal.screen';

import AppLayout from '@giro-pdv-terminal-whitelabel/layouts/App.layout';

import useProtectedByAuth from '@giro-pdv-terminal-whitelabel/hooks/useProtectedByAuth.hook';

const TerminalModule = () => {
  useProtectedByAuth();

  return (
    <AppLayout>
      <Switch>
        <Route exact path={ROUTES.TERMINAL.ROOT} component={TerminalScreen} />
      </Switch>
    </AppLayout>
  );
};

export default TerminalModule;
